
@import '../../../components/glassTheme/Common.scss';

.navbarSignInTool {
}

.navbarSignInTool-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
}

.navbarSignInTool-title {
    @extend .glass-caption;
    @extend .tLeft;
    @extend .italic;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.navbarSignInTool-button {
    @extend .text-button;
    @extend .bold;
}