@import '../../components/glassTheme/Common.scss';
@import '../../components/glassTheme/Contextual.scss';

.scorekeeper-dashboard {
  margin:0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}