@import './Common.scss';

.eventGroupBadge {
    @extend .badge;
    background-color: purple;
}
.messageBadge {
    @extend .badge;
    @extend .italic;
    @extend .diffuse;
    font-weight: 800;
    background-color: darkcyan;
}
.warningBadge {
    @extend .badge;
    @extend .italic;
    opacity: 0.9;
    background-color: darkred;
}
.tcgGameTypeBadge {
    @extend .badge;
    background-color: blue;
}

.vgGameTypeBadge {
    @extend .badge;
    background-color: darkred;
}

.goGameTypeBadge {
    @extend .badge;
    background-color: green;
}

.otherGameTypeBadge {
    @extend .badge;
    background-color: darkorange;
}