
@import '../../components/glassTheme/Common.scss';

.standing-list-item {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.standing-card {
  @extend .diffuse;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 30px;
  padding: 15px 30px;
}

.standing-player-details {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
}

.standing-record-container {
  width: 80px;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  position: relative;
}

.standing-position-box {
  @extend .dark-glass;
  width: 100px;
  height: 60%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}