@import '../../components/glassTheme/Common.scss';

.prizeoutStandings-list {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
}

.prizeoutStandings-list-content {
  margin:0;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-bottom: 100px;
}

.prizeoutStandings-list-items {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.prizeoutStandings-list-item {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prizeout-list-footer {
  display: flex;
  flex-direction: column;
  padding: 50px 0px 130px 0px;
  gap: 20px;
}

.prizeoutStandings-statisticsArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.prizeoutStandings-statisticsButton {
  @extend .text-button;
  @extend .italic;
  @extend .glass-caption;
  color: white;
  width: 100%;
  text-align: center;
  opacity: 0.6;
}