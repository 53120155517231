@import '../components/glassTheme/Common.scss';

html {
  height:100%;
}

body {
  margin:0;
}

.home {
  @extend .unselectable;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeMessage {
  color: black;
  font-size: medium;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}


.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 50px;
  gap: 20px;
}
.menu-item {
  @extend .glass-bubble;
  @extend .diffuse;
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-decoration:none;
  transition: 0.5s ease;
}
.menu-item:hover {
  z-index: 100;
  scale: 1.05;
  transition: 0.5s ease;
}
.menu-item-text {
  text-align: center;
}
.sway-left {
  margin-right: 75px;
}
.sway-right {
  margin-left: 75px;
}