@import '../../components/glassTheme/Common.scss';

.prizeoutDefinition-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
}

.prizeoutDefinition-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.delete-divider {
    width: 105%;
    height: 1px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-bottom: 10px;
}