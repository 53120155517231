
@import '../../components/glassTheme/Common.scss';

.prizeoutStanding-list-item {
  width: 90%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.prizeoutStanding-card {
  @extend .diffuse;
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 30px;
  padding: 5px 10px 5px 30px;
}

.prizeoutStanding-player-details {
  width: 30%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  gap: 5px;
}

.prizeoutStanding-prizeoutButtons-container {
  min-width: 40%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 15px;
}

.prizeoutStanding-record-container {
  width: 80px;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
  padding: 5px 0px;
  position: relative;
}

.prizeoutStanding-position-box {
  @extend .dark-glass;
  width: 100px;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.prizeout-comment {
  @extend .glass-body;
}

.prizeoutStanding-history-button-container {
  width: 50px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.prizeoutStanding-comment-container  {
  width: 20%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  gap: 5px;
}

.prizeoutStanding-history-container {
  @extend .dim-glass-card;
  max-width: 800px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 10px 10px 10px;
  margin: 10px;
}

.prizeoutStanding-history-list {
  @extend .dark-glass-card;
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: auto;
  gap: 5px;
  padding: 10px;
}

.prizeoutStanding-history-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  position: relative;
  gap: 10px;
  padding: 0 20px;
}

.prizeoutStanding-history-change-list {
  width: 70%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 5px;
}

.prizeoutStanding-history-change {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 5px;
}

.prizeoutStanding-history-change > * {
  flex: 1;
}

.prizeoutStanding-history-data-label {
  @extend .glass-caption;
  width: 25%;
}

.prizeoutStanding-history-change-arrow {
  @extend .glass-element-subtle;
}

.prizeout-comment-edit-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.prizeout-commentInput {
  @extend .glass-input;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
  padding: 5px;
}

.prizeout-commentInput-populated {
  @extend .prizeout-commentInput;
  background: rgba(20, 20, 20, 0.7);
  outline: 2px solid rgba(255, 255, 255, 0.5);
  min-height: 4em;
}

.prizeout-comment-edit-button-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 5px;
  padding: 2px;
  margin-right: -40px;
}

.prizeoutStanding-markerContainer {
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 105;
  margin-left: -45px;
  position: absolute;
  gap: 5px;
}

.prizeoutStanding-markerBase {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1.5px solid white;
  height: 30px;
  width: 30px;
  aspect-ratio: 1 / 1;
  animation: pop-in 0.3s ease-out forwards;
}

.prizeoutStanding-markerBase.pop-out {
  animation: pop-out 0.3s ease-in forwards;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop-out {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(0);
  }
}

.prizeoutStanding-markerComplete {
  @extend .prizeoutStanding-markerBase;
  background-color: $sentiment-positive;
}
.prizeoutStanding-markerWarning {
  @extend .prizeoutStanding-markerBase;
  background-color: $sentiment-warning;
}
.prizeoutStanding-markerIcon {
    padding: 1px;
    color: white;
}