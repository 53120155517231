@import '../../components/glassTheme/Common.scss';

.glass-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.glass-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #a4a4a4;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}
.glass-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #dad8d8;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}
.glass-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}
.glass-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}
.glass-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
.glass-switch input { display: none; }
.glass-switch input:checked + i { background-color: $interactive-positive; }
.glass-switch input:disabled + i { filter: brightness(75%); }
.glass-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
.glass-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }