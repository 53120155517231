
@import '../../components/glassTheme/Common.scss';

.eventSelector-content {
  margin:0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.eventSelector-card {
  @extend .glass-card;
  width: 800px;
  height: 80vh;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding: 15px 30px;
}

.eventSelector-listControls {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.eventSelector-list {
  width: 100%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.eventSelector-badgeRail {
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 3px 0px;
}

.event-item {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 15px;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eventSelector-title {
  @extend .glass-h3;
  @extend .italic;
  padding-top: 10px;
}
.eventSelector-filter-button {
  @extend .glass-button;
  @extend .glass-caption;
  width: 100px;
}

.eventSelector-button {
  @extend .glass-button;
  width: 100px;
}

.eventSelector-search {
@extend .glass-input;
  width: 100%;
}

.eventSelector-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.eventSelector-header-controls {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.eventSelector-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.eventSelector-selectControls {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}