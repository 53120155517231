@import '../../../components/glassTheme/Common.scss';
.errorPage {
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  gap: 15px;
}

.errorPage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  gap: 10px;
}

.errorPage-iconCard {
  @extend .glass;
  @extend .diffuse;
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  transform: rotate(-10deg);
  margin: 40px;
  gap: 10px;
  padding: 10px;
}

.errorPage-icon {
  transform: rotate(10deg);
  width: 60%;
  height: 60%;
  color: white;
  opacity: 0.8;
  animation: fade-in 0.3s ease-in-out;
}

.errorPage-iconLabel {
  @extend .glass-title;
  @extend .bold;
  text-align: center;
}

.errorPage-title {
  @extend .glass-h1;
  @extend .bold;
  text-align: center;
}

.errorPage-subtitle {
  @extend .glass-h3;
  @extend .italic;
  text-align: center;
}

.errorPage-description {
  @extend .glass-body;
  text-align: center;
}

.errorPage-button {
  @extend .glass-button;
  transform: scale(1.2);
  width: 120px;
}