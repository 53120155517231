@import '../../components/glassTheme/Common.scss';

.fusionControlPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-top: 15px;
}
.fusionControl-fixedElements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.fusionControl-fixedElementsLeft {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 60%;
  gap: 5px;
  padding-top: 10px;
}
.fusionControl-fixedElementsRight {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 35%;
  padding: 10px, 10px;
}
.fusionControls-advanceControlsButton {
  @extend .text-button;
  @extend .italic;
  padding: 10px;
}

.fusionControlSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 10px, 10px;
}

.downloadButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 10px, 10px;
}

.fusionControls {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px 10px 0 10px;
}

.fileSelectControls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 30px, 10px;
}

.overrideControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.advanceControls {
  @extend .overrideControls;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  
  &.show {
    max-height: 500px; // Adjust this value based on the content height
    transition: max-height 0.5s ease-in;
  }
}

.timerControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px, 10px;
}

.timerControlsDataSections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding-left: 15px;
}

.timerControlsData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  width: 100%;
  gap: 5px;
}

.tdfDownloadButton {
  @extend .glass-button-icon;
  @extend .glass-caption;
  @extend .bold;
  width: 110px;
  margin: 5px;
}

.fileSelectButton {
  @extend .glass-button-icon;
  width: 150px;
  margin-top: 5px;
}

.syncIcon {
  width: 80px;
  height: 40px;
  margin: 30px;
  color: white;
  opacity: 0.5;
  animation: spin 5s infinite linear;
  animation-fill-mode: both;
  animation-play-state: running;
  align-self: start;
}

.syncIconPair {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.fusionControls .syncIcon {
  animation-play-state: running;
}

.fusionChannelSelect {
  @extend .glass-select;
  width: 80%;
}

.fusion-data-pair {
  @extend .data-pair;
  width: 80%;
}

.fusion-warningBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 10px;
  padding: 10px 0px;
}

.fusion-footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  gap: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}