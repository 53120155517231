*{
    margin: 0px;
    padding: 0px;
}

.context {
    width: 100%;
    position: absolute;
    top:50vh;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}
// .area{
//     width: 100%;
//     height:100vh;
//     background: 
//         radial-gradient(circle at 15% 10%, rgba(82,51,189,1) 0%, transparent 70%),
//         radial-gradient(circle at 75% 25%, rgba(253,29,29,1) 85%, transparent 50%),
//         radial-gradient(circle at 95% 10%, rgba(204,119,0,1) 0%, transparent 30%),
//         radial-gradient(circle at 85% 90%, rgba(253,29,29,1) 0%, transparent 150%);
// }
// .area{
//     width: 100%;
//     height:100vh;
//     background: 
//         radial-gradient(circle at 15% -10%, rgb(177, 59, 240) 0%, transparent 70%),
//         radial-gradient(circle at 75% 25%, rgb(23, 80, 186) 0%, transparent 50%),
//         radial-gradient(circle at 95% 10%, rgb(236, 52, 144)0%, transparent 40%),
//         radial-gradient(circle at 85% 90%, rgb(5, 142, 142) 0%, transparent 150%);
// }
// .area{
//     width: 100%;
//     height:100vh;
//     background: linear-gradient(360deg, rgba(82,51,189,1) 0%, rgba(253,29,29,1) 85%, rgba(204,119,0,1) 100%);
// }
.area {
    font: 16px/1.4 sans-serif; letter-spacing: 0.12em;
    min-height: 150vh;
    padding: 2em;
    margin: 0;
    color: hsla(0, 0%, 100%, 0.85); 

    background-color: #170d24;
    background-image:
    radial-gradient(ellipse at 10% 90%, #3c2d83 0%, transparent 55%),
    radial-gradient(ellipse at 90% 90%, #c33c65 0%, transparent 55%),
    radial-gradient(ellipse at 90% 10%, #4a74dc 0%, transparent 55%),
    radial-gradient(ellipse at 10% 10%, #35244f 0%, transparent 55%);
}

.circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0%{
        translate: 0px;
        rotate: 0deg;
        opacity: 1;
        border-radius: 20%;
    }

    100%{
        translate: -1000px -1000px;
        rotate: 200deg;
        opacity: 0;
        border-radius: 20%;
    }
}