@import '../../components/glassTheme/Common.scss';
@import '../../components/glassTheme/Contextual.scss';

.events-content {
  margin:0;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-bottom: 100px;
}

.eventsControls {
  width: 100%;
}

.events {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.event-card-base {
  @extend .glass-bubble;
  @extend .diffuse;
  width: 90%;
  max-width: 1000px;
  height: 120px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 0.3s linear, background-color 1s ease;
  gap: 10px;
}
.event-card {
  @extend .event-card-base;
}
.event-card:hover {
  @extend .hovered-glass;
  transition: 0.3s linear, background-color 1s ease;
  scale: 1.01;
}
.event-card-selected {
  @extend .event-card-base;
  @extend .dark-glass;
  scale: 1.01;
}

.nav-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 20px;
}

.nav-button {
  @extend .glass-button-icon;
  font-size: small;
  cursor: pointer;
  height: 25px;
  width: 120px;
  text-decoration: none;
}
.action-button:hover {
}

.event {
  width: 90%;
  height: 120px;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.eventId-label {
  @extend .glass-boring;
}
.event-details-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: 5px;
}

.event-data-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.event-data-column-left {
  @extend .event-data-column;
  align-items: start;
}
.event-data-section-base {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.event-data-section-start {
  @extend .event-data-section-base;
  justify-content: start;
  width: 35%;
}
.event-data-section-middle {
  @extend .event-data-section-base;
  justify-content: center;
  width: 35%;
}
.event-data-section-end {
  @extend .event-data-section-base;
  justify-content: center;
  width: 15%;
}
.event-data-section-action {
  @extend .event-data-section-base;
  justify-content: end;
  width: 5%;
}
.event-data-action-icon {
  @extend .glass-element-subtle;
  width: 20px;
  height: 20px;
}

.searchError {
  @extend .glass-bubble;
  @extend .raised-glass;
  width: 60%;
  height: 100px;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 10;
}

.searchErrorIcon {
  @extend .glass-element-subtle;
  width: 30px;
  height: 30px;
}

.state-icon {
  @extend .glass-element-subtle;
  width: 35px;
  height: 35px;
  margin: 2px;
  align-self: center;
}
.state-label {
  @extend .glass-body;
  @extend .italic;
  width: 45px;
  align-self: center;
  padding-top: 5px;
}

.event-title {
  @extend .glass-h2;
  width: 100%;
  text-align: left;
  margin: 0;
}

.event-subtitle {
  @extend .glass-h3;
  text-align: left;
}

.event-detail-header {
  @extend .glass-caption;
  text-align: left;
}
.event-detail {
  @extend .glass-body;
  text-align: left;
}