@import '../../components/glassTheme/Common.scss';

.action-field-box {
    @extend .dark-glass;
    @extend .diffuse;
    display: flex;
    flex-direction: row;
    height: 60px;
    min-width: 60px;
    border-radius: 50px;
    padding: 10px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.action-field-input {
    border: none;
    background: none;
    width: 0;
    padding: 0;
    color: #fff;
    float: left;
    font-size: 16px;
    transition: .3s;
    line-height: 40px;
}

.action-field-input::placeholder {
    color: #dbc5b0;
}

.action-field-btn {
    color: white;
    float: right;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .3s;
    padding: 10px;
}

.action-field-icon {
    width: 25px;
    height: 25px;
    fill: $sentiment-negative;
}

.active-icon {
    fill: grey;
    transition: .3s;
}

.action-field-input:focus,
.action-field-input:not(:placeholder-shown) {
    width: 180px;
    padding: 0 6px;
}

.action-field-box:hover > .action-field-input {
    width: 180px;
    padding: 0 6px;
}

.action-field-box:hover > .action-field-btn,
.action-field-input:focus + .action-field-btn,
.action-field-input:not(:placeholder-shown) + .action-field-btn {
    visibility: none;
}
