@import '../../components/glassTheme/Common.scss';

.standings-list {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
}

.standings-list-content {
  margin:0;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-bottom: 100px;
}

.standings-list-items {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.standings-list-item {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}