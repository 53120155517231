
@import '../../components/glassTheme/Common.scss';

.prizeoutButton-button {
    @extend .dark-glass;
    width: 100px;
    height: 65px;
    border-radius: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .3s;
    padding: 5px;
    gap: 0px;
    user-select: none;
}

.paidOut {
    border: 4px solid $interactive-positive;
}

.prizeoutButton-corner-element {
    @extend .sync-badge;
    bottom: -5px;
    left: -10px;
}

.prizeoutButton-icon {
    width: 20px;
    height: 20px;
    height: 100%;
    position: relative;
}

.prizeoutButton-label-container {
    @extend .dark-glass;
    @extend .diffuse;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 200px;
    border-radius: 50px;
    padding: 10px;
    transition: opacity .3s, transform .3s;
    transition-delay: 0s, 0s;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
    position: absolute;
}

.prizeoutButton-button:hover .prizeoutButton-label-container {
    opacity: 1;
    transition-delay: 1.5s, 0s;
}

.prizeoutButton-HoverLabel {
    @extend .glass-body;
    text-align: center;
    font-size: small;
    overflow: visible;
}

.prizeoutButton-HeaderLabel {
    @extend .glass-h1;
    font-size: large;
    text-align: center;
    overflow: visible;
}

.prizeoutButton-HeaderLabel-prefix {
    @extend .glass-body;
    @extend .bold;
    @extend .italic;
    text-align: center;
    overflow: visible;
    margin-bottom: -4px;
    color: $sentiment-warning;
}

.prizeoutButton-selected {
    fill: $interactive-positive;
}
.prizeoutButton-deselected {
    fill: lightgrey;
}
.prizeoutButton-button:hover .prizeoutButton-selected {
    fill: lightgrey;
}
.prizeoutButton-button:hover .prizeoutButton-deselected {
    fill: $interactive-positive;
}
.prizeoutButton-button:hover {
    @extend .glass;
    scale: 1.1;
}

.prizeoutButton-headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    gap: 3px;
}