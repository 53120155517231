@import '../../components/glassTheme/Common.scss';

.prizeoutDashboard-content {
  margin:0;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}