@import '../../../components/glassTheme/Common.scss';

.prizeoutDefinitionElement {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    gap: 15px;
    padding: 5px 0;
}

.prizeoutDefinitionElementColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0px;
}

.prizeoutDefinitionElementColumn-data {
    @extend .prizeoutDefinitionElementColumn;
    width: 60%;
    gap: 15px;
}

.prizeoutDefinitionElementColumn-standings {
    @extend .prizeoutDefinitionElementColumn;
    width: 40%;
}

.prizeoutDefinitionElementTypeRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.prizeoutDefinitionElementQuantitiesRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.prizeoutDefinitionElementItem input {
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    max-height: 30px;
    margin: 2px;
}

.prizeoutDefinitionElementItem {
    @extend .data-pair;
    width: 100%;
    min-height: 35px;
}

.prizeoutDefinitionDeleteButton {
    width: 10px;
    height: 10px;
    margin: 0;
    padding: 0;
}

.prizeoutDefinitionAddStandingRangeButton {
    @extend .glass-button;
    width: 100%;
    margin: 15px 0 0 0;
}

.prizeoutDefinitionElementStandingRow {
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: start;
    align-items: start;
    width: 100%;
}

.prizeoutDefinition-label {
    @extend .glass-caption;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
    text-align: center;
}

.prizeoutDefinitionStandingLabel {
    @extend .prizeoutDefinition-label;
    align-self: center;
    justify-self: center;
    text-align: center;
    display: flex;
    align-items: start;
}

.prizeoutDefinitionElementTypeSelect {
    @extend .glass-select;
    width: 100%;
    height: 30px;
}