@import '../../components/glassTheme/Common.scss';

.createEventDashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.validationMessage {
  @extend .glass-body;
  text-align: center;
}

.validationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.creatEvent-prizeoutSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.create-event-input {
  @extend .data-pair;
  width: 100%;
}
.createButton {
  @extend .glass-button-icon;
  width: 180px;
  margin-bottom: 5px;
}

.createEvent-togglesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}