@import '../../components/glassTheme/Common.scss';

.glass-number-input {
    @extend .recessed-glass-component;
    height: 30px;
    min-height: 30px;
    width: 46px;
    height: 25px;
}

.glass-number-input[type="number"]::-webkit-inner-spin-button {
    display: none;
}