
@import '../../../components/glassTheme/Common.scss';

.navbarSyncToolContainer {
    height: 100%;
    overflow: visible;
}

.navbarSyncToolBase {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    transition: width height 0.3s ease-in-out;
}
.navbarSyncToolDefault {
    @extend .navbarSyncToolBase;
}
.navbarSyncToolSuccess {
    @extend .navbarSyncToolBase;
}
.navbarSyncToolWarning {
    @extend .navbarSyncToolBase;
}
.navbarSyncToolError {
    @extend .navbarSyncToolBase;
}
.navbarSyncToolUploading {
    @extend .navbarSyncToolBase;
}

.navbarSyncToolData {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
    justify-content: center;
}

.resultSyncIndicatorDefault {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.5px solid gray;
    height: 60px;
    width: 60px;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.resultSyncIcon {
    color: white;
    animation: fadeOut 0.25s ease-in-out forwards, fadeIn 0.25s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

@keyframes fadeOut {
    0% {
        scale: 1;
    }
    100% {
        scale: 0;
    }
}
.resultSyncIconActive {
    color: white;
    animation: rotateClockwise 10s linear infinite, fadeOut 0.25s ease-in-out forwards, fadeIn 0.25s ease-in-out forwards;
}
@keyframes rotateClockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.uploadingIcon {
    color: white;
    animation: pulsing 1s infinite;
}
.resultSyncTitle {
    @extend .glass-h4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.resultSyncSubtitle {
    @extend .glass-caption;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resultSyncIndicator {
    position: relative;
}

.resultSyncIndicatorStatusBase {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid gray;
    transform: translate(-15%, 15%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.resultSyncIndicatorStatusDefault {
    @extend .resultSyncIndicatorStatusBase;
    background-color: $sentiment-neutral;
}
.resultSyncIndicatorStatusSuccess {
    @extend .resultSyncIndicatorStatusBase;
    background-color: $sentiment-positive;
}
.resultSyncIndicatorStatusWarning {
    @extend .resultSyncIndicatorStatusBase;
    background-color: $sentiment-warning;
}
.resultSyncIndicatorStatusError {
    @extend .resultSyncIndicatorStatusBase;
    background-color: $sentiment-negative;
}
.resultSyncIndicatorStatusFetching {
    @extend .resultSyncIndicatorStatusBase;
    background-color: $sentiment-neutral;
}
.statusIcon {
    color: white;
    height: 16px;
}