
@import '../../components/glassTheme/Common.scss';

.trackerContainer {
}
.uploadTracker {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    padding: 7px;
}
.uploadTrackerData {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: start;
    justify-content: center;
    align-self: center;
    padding-bottom: 2px;
}

.resultIndicatorBase {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35px;
    min-width: 35px;
    border-radius: 50%;
    border: 0.5px solid gray;
}
.resultIndicatorDefault {
    @extend .resultIndicatorBase;
    background-color: $sentiment-neutral;
}
.resultIndicatorSuccess {
    @extend .resultIndicatorBase;
    background-color: $sentiment-positive;
}
.resultIndicatorWarning {
    @extend .resultIndicatorBase;
    background-color: $sentiment-warning;
}
.resultIndicatorError {
    @extend .resultIndicatorBase;
    background-color: $sentiment-negative;
}
.resultIndicatorUploading {
    @extend .resultIndicatorBase;
    background-color: $sentiment-neutral;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.historyChevronContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 18px 0px 0px;
    width: 100%;
}
.historyChevron {
    color: white;
    height: 15px;
}

.resultIcon {
    color: white;
    height: 18px;
    width: 18px;
}
.uploadingIcon {
    color: white;
    height: 15px;
    width: 15px;
    animation: pulsing 1s infinite;
}
.resultTitle {
    @extend .glass-h4;
    text-overflow: ellipsis;
    text-align: left;
}
.resultSubtitle {
    @extend .glass-caption;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logHistoryEmpty {
    @extend .glass-caption;
    @extend .dark-glass;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: italic;
    margin: 5px 8px 8px 8px;
    border-radius: 20px;
    padding: 10px;
    overflow: scroll;
}
.logHistory {
    @extend .logHistoryEmpty;
    height: 350px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}
.logHistorySection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    justify-content: start;
    padding: 5px;
    border-radius: 10px;
    width: 100%;
}
.logHistorySectionTitle {
    @extend .glass-body;
    @extend .bold;
    text-align: left;
}
.logHistoryMessage {
    @extend .glass-caption;
    text-align: left;
    padding-left: 5px;
}