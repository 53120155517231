
@import '../../components/glassTheme/Common.scss';

.action-button {
    @extend .dark-glass;
    @extend .diffuse;
    display: flex;
    flex-direction: row;
    color: #fff;
    height: 60px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    gap: 5px;
    padding: 20px;
}

.action-icon {
    width: 15px;
    height: 15px;
    fill: gray;
}

.action-label {
    @extend .glass-h2;
    color: lightgrey;
    text-align: center;
    font-size: small;
    overflow: visible;
}

.action-button:hover {
    @extend .glass;
    scale: 1.1;
}